body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.app-header {
  background-color: #f3f3f3 !important;
}

.construction-video {
  position: relative;
  margin: 0px 10px;
}

.content {
  width: 70%;
  margin-top: 7%;
  margin-left: 15%;
  text-align: center;
  position: absolute;
  top: 10%;
  color: #fff;
  font-size: 40px;
}

.contact-social-info {
  border: 1px solid black;
  height: 500px;
  width: 500px;
}

.container-title {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.container-title:after,
.container-title:before {
  content: "";
  border-bottom: 7px solid#f06859;
  order: 2;
  width: 50%;
}
.container-title:after {
  margin-left: auto;
  margin-top: -7px;
  border-color: #333157;
}

.card-desc-ellipsis {
  font-size: medium !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-list-desc-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-gallery-image {
  float: left;
  width: 500px !important;
  height: 250px;
  object-fit: cover !important;
}

.d-flex {
  display: flex;
}

.address-location {
  margin-top: 4px;
  margin-left: 29px;
}

.icon-style {
  margin-right: 5px;
  color: #f06859;
}

.footer {
  background-color: #333333;
  color: #b2b2b2;
  font-size: 12px;
  text-align: center;
  margin: auto;
  padding: 20px;
}
